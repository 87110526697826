/**
 * Type Guards
 */
export function isLiteralElement(el) {
    return el.type === 0 /* literal */;
}
export function isArgumentElement(el) {
    return el.type === 1 /* argument */;
}
export function isNumberElement(el) {
    return el.type === 2 /* number */;
}
export function isDateElement(el) {
    return el.type === 3 /* date */;
}
export function isTimeElement(el) {
    return el.type === 4 /* time */;
}
export function isSelectElement(el) {
    return el.type === 5 /* select */;
}
export function isPluralElement(el) {
    return el.type === 6 /* plural */;
}
export function createLiteralElement(value) {
    return {
        type: 0 /* literal */,
        value: value
    };
}
export function createNumberElement(value, style) {
    return {
        type: 2 /* number */,
        value: value,
        style: style
    };
}
